import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="dialog-actions"
export default class extends Controller {
  connect() {
    this.show();

    this.element.addEventListener('click', (e) => {
      if(e.target.className == 'dialog' && e.target.className !== "dialog__container"){
        this.element.close();
      }
    })
  }
  close() {
    this.element.close();
  }
  show() {
    this.element.showModal();
  }
}
